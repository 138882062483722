/*! {!project_name!} v0.2.1 */
/**
 * JS for: {!project_name!}
 *
 * Author: {!dev_alias!} @ {!dev_agency!}
 *
 * TOC:
 *
 * Modules
 * jQuery document ready
 * 
 * @todo Add each module/section needed
 * 
 */
/* Modules */
const $ = require('jquery');
const swiper = require('swiper');

/**
 * Scroll to
 * Page scroll to DOM selector
 * 
 * @param string str_selector The object id/class.
 * @param string|integer int_duration A string or number determining how long the animation will run. 
 */
function scroll_to(selector, offset, duration)
{
	scroll_position = $('' + selector + '').offset().top + offset;
	$('html, body').animate({scrollTop: scroll_position }, duration);
}

/* jQuery document ready */
$(document).ready(function()
{
	/* The swiper stuff */
	if (0 < $('.swiper-container.home').length) {
		var slider_loop = $('.swiper-container.home .swiper-slide ').length - 1;
		var swiperHome = new swiper ('.swiper-container.home', {
		    direction: 'horizontal',
		    slidesPerview: 1,
		    loop: slider_loop,
		    watchOverflow: true,
		    touchEventsTarget: 'wrapper',
		    grabCursor: true, 
		    autoplay: {
		    	delay: 16000,
		  	},
		  	autoplay: false,
		    scrollbar: {
		    	hide: true,
		    },
		    navigation: {
			    nextEl: '.swiper-button-next',
			    prevEl: '.swiper-button-prev',
				},
		    pagination: {
		    	el: '.swiper-pagination',
		    	type: 'bullets',
		    	clickable: true,
		  	},
		  	keyboard: {
		    	enabled: true,
		    }
		});
	}
	/* The submenu link */
	$('#link-opciones-de-credito').on('click', function(e)
	{
		e.preventDefault();
		$('.navigation.sub').slideToggle();
	});
	/* The scroll to contact */
	$('#link-contacto').on('click', function(e)
	{
		e.preventDefault();
		scroll_to('#contacto', -167, 'normal');
	});
	/* The toggle list buttons */
	$('.list-toggle').on('click', function(e)
	{
		e.preventDefault();
		$(this).next('ul').slideToggle();
	});
	/* The opciones de credito last word decoration */
	$('.heading.opciones-de-credito').html(function()
	{
		var text = $(this).text().split(' ');
		var last = text.pop();
		return text.join(' ') + (text.length > 0 ? ' <span class="complementary-color-1">' + last + '</span>' : last);   
	});

});
